// alert('dsi funcoes')
console.log('DSi funcoes');




import { cripto } from './DSi_rotinas';
import { tabela_create } from './DSi_sql';


//registra log
export async function registra_log(tipo,log){


    let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
    // console.log('hoje:'+ hoje);

    let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
    // console.log('today:'+ today);

    let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
    let hora_curta = hora.slice(0, 5)
    // console.log('hora = '+ hora);
    // console.log('hora = '+ hora_curta);



    let user = cripto(sessionStorage.getItem('84hfjdyruxy1_is'))
    user = user.trim()

    let nivel = 0

    if      (tipo == 'GERENCIA')    { nivel = 5}
    else if (tipo == 'ORCAMENTO')   { nivel = 3}
    else if (tipo == 'AGENDA')      { nivel = 2}
    else if (tipo == 'ATENDIMENTO') { nivel = 1}
    else if (tipo == 'GERENCIA')    { nivel = 0}

    let items = {
        ATIVO : 'S',
        TIPO : tipo,
        DATA  : today,
        HORAS : hora_curta+' '+ user,
        CODIGO_OPERADOR : sessionStorage.getItem('8ukjkljdfai'),
        OPERADOR : user,
        DESCRICAO : log,
        DESCRICAO_2 : localStorage['v'],
        NIVEL : nivel
    }

    // console.log('items:', items);

    // if (user !== 'A'){
        await tabela_create(this, 'REGISTRO_LOG', items) //tem que definir o export na declaracao da funcao   
    // }

};


export default {
    registra_log
};
